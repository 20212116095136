export const YearSize = ({ wine, className = '' }) => {
  const jahrgang =
    wine.attributes.jahrgang && wine.attributes.jahrgang?.value != '0'
      ? wine.attributes.jahrgang.value
      : null;

  var variantName;
  if (wine.defaultVariant?.name) {
    variantName = wine.defaultVariant?.name.split(', ')[0]
  }

  return (
    <div className={className}>
      {jahrgang}
      {variantName && (
        <>
          {jahrgang && <>,&nbsp;</>}
          {variantName}
        </>
      )}
    </div>
  );
};
