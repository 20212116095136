import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useContext } from 'react';
import { PRODUCT_PREVIEW_IMAGE_SIZE } from '../../lib/images';
import { trackProductClick } from '../../lib/tracking';
import ProductImage from '../product/image';
import { WishlistContext } from '../wishlist/wishlist-context';
import { FavAction } from './actions';

const DynamicAddToCart = dynamic(() => import('../cart/add-to-cart'));

const ProductPreview = ({
  item,
  className = '',
  priceMeta = undefined,
  children = undefined,
  imageAddition = undefined,
  listName = undefined,
  lead = undefined,
  imageClassName = '',
  imageStyle = {},
  allowMultipleAdd = true,
  imageSize = PRODUCT_PREVIEW_IMAGE_SIZE,
}) => {
  const onClick = () => trackProductClick(item, listName);

  const { addToWishlist, isInWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const _onAddToWishlist = () => {
    addToWishlist({ type: item.type, id: item.id });
  };
  const _onRemoveFromWishlist = () => {
    removeFromWishlist({ type: item.type, id: item.id });
  };

  const blackFavAction = item.type == 'wein' || item.type == 'set';

  return (
    <Link
      href={{
        pathname: '/[slug]/[product]',
        query: {
          slug: item.category.slug,
          product: item.slug,
        },
      }}
      onClick={onClick}
      className={`preview-item relative flex h-full text-center ${className}`}
    >
      <div className='relative flex h-full w-full flex-col items-center justify-center gap-3'>
        <ProductImage
          size={imageSize}
          className={imageClassName + ' w-full'}
          product={item}
          // style={imageStyle}
        />

        <FavAction
          color={blackFavAction ? 'black' : 'white'}
          className={`absolute left-0 top-0 p-3 ${
            blackFavAction ? '-ml-3 -mt-3' : ''
          }`}
          onAddToWishlist={_onAddToWishlist}
          onRemoveFromWishlist={_onRemoveFromWishlist}
          isInWishlist={isInWishlist({
            type: item.type,
            id: item.id,
          })}
        />

        {imageAddition && <>{imageAddition}</>}
        <div className='flex w-full flex-grow flex-col items-center justify-start'>
          <h3 className={`font-base h4 text-black ${lead ? '' : 'mb-2'}`}>
            {item.name}
          </h3>
          {lead && <p className='uppercase mb-2'>{lead}</p>}
          {children && <>{children}</>}
        </div>

        <DynamicAddToCart
          product={item}
          allowMultipleAdd={allowMultipleAdd}
          priceMeta={priceMeta}
          className='mb-px self-stretch'
        />
      </div>
      <div className='preview-item-border'></div>
    </Link>
  );
};

ProductPreview.isProduct = true;

export default React.memo(ProductPreview);
