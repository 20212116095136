export const Country = ({
  wine: {
    attributes: { land, appellation },
  },
  className = '',
  centered = true,
}) => {
  if (!land) {
    return <></>;
  }

  return (
    <div
      className={`flex flex-row ${
        centered ? 'justify-center' : ''
      } ${className}`}
    >
      <img
        className='mr-3 h-5 w-5'
        src={`/static/country/${land.valueSlug}.svg`}
        alt=''
      />
      <span className='enforce-one-line'>
        {land.value}, {appellation.value}
      </span>
    </div>
  );
};
