import { asHTML } from '@prismicio/helpers';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { t } from '../../generated/i18n';
import {
  POST_PREVIEW_IMAGE_WIDTH,
  calc16to9ImageProps,
  img16to9Loader,
} from '../../lib/images';
import { RichText } from '../cms/rich-text';
import { ReadMoreLink } from '../common/link';
import { FavAction } from '../product/actions';
import { WishlistContext } from '../wishlist/wishlist-context';
import PostMeta from './meta';

export const PostImage = ({
  image = null,
  className = 'rounded-lg-img rounded-lg',
  preload = false,
}) => {
  if (!image) {
    return (
      <div
        className={`${className} aspect-h-9 aspect-w-16 bg-inputPlaceholder`}
      />
    );
  }
  const props = calc16to9ImageProps(image, POST_PREVIEW_IMAGE_WIDTH);

  return (
    <div className={`${className} ${props.className ?? ''}`}>
      <Image loader={img16to9Loader} priority={preload} {...props} />
    </div>
  );
};

const PostPreview = ({ item: post, className = '' }) => {
  const router = useRouter();
  const { addToWishlist, isInWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  if (!post) {
    return <div></div>;
  }

  const _onAddToWishlist = () => {
    addToWishlist({ type: 'post', id: post._meta.id });
  };
  const _onRemoveFromWishlist = () => {
    removeFromWishlist({ type: 'post', id: post._meta.id });
  };

  const href = `/blog/${post._meta.uid}`;

  const onClick = () => {
    router.push(href).then(() => window.scrollTo(0, 0));
  };

  return (
    <div onClick={onClick} className={`${className} preview-item`}>
      <Link
        href={href}
        className={`min-h-440px relative flex h-full bg-white text-center`}
      >
        <div className='flex h-full cursor-pointer flex-col justify-between overflow-hidden overflow-ellipsis'>
          <PostImage image={post.heading_image} />

          <FavAction
            className='absolute left-0 top-0 p-3'
            onAddToWishlist={_onAddToWishlist}
            onRemoveFromWishlist={_onRemoveFromWishlist}
            isInWishlist={isInWishlist({
              type: 'post',
              id: post._meta.id,
            })}
          />

          <div className='border-b border-0.3 py-6'>
            <PostMeta post={post} link={false} />
          </div>

          <div className='flex flex-grow flex-col justify-between p-6'>
            {post.title && (
              <h3>
                {typeof post.title === 'string'
                  ? post.title
                  : post.title[0].text}
              </h3>
            )}
            {post.lead && (
              <RichText
                className='mt-2 flex-grow'
                elements={
                  typeof post.lead === 'string' ? post.lead : asHTML(post.lead)
                }
              />
            )}
            <ReadMoreLink
              onClick={onClick}
              className='mx-auto mt-6'
              label={t('toPostDetail')}
            />
          </div>
        </div>
        <div className='preview-item-border'></div>
      </Link>
    </div>
  );
};

export default React.memo(PostPreview);
