const monthNames = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const EventMeta = ({ event, className = '' }) => {
  const items = [];

  if (event.metadata.ort) {
    items.push(event.metadata.ort);
  }

  if (event.metadata.datum) {
    const [day, month, year] = event.metadata.datum.split('.');
    items.push(`${day}. ${monthNames[parseInt(month) - 1]} ${year}`);
  }

  // if (event.metadata.start) {
  //   items.push(
  //     `${event.metadata.start}${
  //       event.metadata.ende ? ' - ' + event.metadata.ende : ''
  //     }`,
  //   );
  // }

  return <div className={className}>{items.join(' | ')}</div>;
};

export default EventMeta;
