import ProductPreview from '../product/preview';
import { StructureIcon, getStructuredText } from './attributes';
import { Country } from './country';
import { YearSize } from './year-size';

const WinePreview = ({ item, ...props }) => {
  const structureText = getStructuredText(item);

  return (
    <ProductPreview
      lead={item.brand}
      item={item}
      priceMeta={<YearSize wine={item} />}
      {...props}
    >
      <Country wine={item} />
      <div className='mt-2 flex'>
        <StructureIcon
          structureText={structureText}
          structure={item.attributes.struktur?.value}
          className='mr-2 w-[20px]'
        />
        <span className='enforce-one-line font-black'>{structureText}</span>
      </div>
    </ProductPreview>
  );
};

WinePreview.isProduct = true;

export default WinePreview;
