import Link from 'next/link';
import { useRouter } from 'next/router';
import { convertStrToDate } from '../../lib/date';

const TagLink = ({ tag, router }) => (
  <Link
    href={`/blog?filter=tags--${tag}`}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      router.push(`/blog?filter=tags--${tag}`);
    }}
  >
    #{tag}{' '}
  </Link>
);
const PostMeta = ({ post, className = '', link = true }) => {
  const router = useRouter();
  return (
    <div className={`${className} whitespace-nowrap`}>
      {convertStrToDate(
        post.publish_date1
          ? post.publish_date1
          : post._meta.firstPublicationDate,
      )}
    </div>
  );
};

export default PostMeta;
